import styles from "./Nav.module.css";

const Nav: React.FC<{
  onNextClick: () => void;
  onBackClick: () => void;
  page: number;
  pageRange: number;
}> = (props) => {
  return (
    <div className={styles.Nav}>
      <button onClick={props.onBackClick} disabled={props.page === 1}>
        Previous page
      </button>

      <button
        onClick={props.onNextClick}
        disabled={props.page === props.pageRange}
      >
        Next Page
      </button>
      <p>
        Page {props.page} of {props.pageRange}
      </p>
    </div>
  );
};

export default Nav;
