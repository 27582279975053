import Photo from "../models/photo";
import styles from "./Gallery.module.css";

const Gallery: React.FC<{ photos: Photo[] }> = (props) => {
  return (
    <div className={styles.gallery}>
      <ul>
        {props.photos.map(({ id, server, secret, title }) => (
          <li key={id}>
            <img
              src={`https://live.staticflickr.com/${server}/${id}_${secret}.jpg`}
              alt={title}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Gallery;
