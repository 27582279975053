import React, { useRef } from "react";
import styles from "./SearchBox.module.css";

const SearchBox: React.FC<{ onSearch: (query: string) => void }> = (props) => {
  // Get the value from input form in uncontrolled way
  const searchInputRef = useRef<HTMLInputElement>(null);

  const submitHandler = (event: React.FormEvent) => {
    // prevent default form behavior in submission
    event.preventDefault();

    const searchText = searchInputRef.current!.value;
    // validating input value
    if (searchText.trim().length === 0) {
      props.onSearch(" ");
      return;
    }
    props.onSearch(searchText);
    // reset the input field after submission
    searchInputRef.current!.value = "";
  };

  return (
    <form className={styles.form} onSubmit={submitHandler}>
      <label htmlFor="search">Search:</label>
      <input
        type="text"
        id="search"
        placeholder="e.g. Polestar Cars"
        ref={searchInputRef}
      />
      <button>Search</button>
    </form>
  );
};

export default SearchBox;
