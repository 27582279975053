import React, { useState } from "react";
import Gallery from "./components/Gallery";
import SearchBox from "./components/SearchBox";
import Nav from "./components/Nav";

import styles from "./FlickrGallery.module.css";

import useFetch from "./hooks/use-fetch";

const FlickrGallery: React.FC = () => {
  // define state for search queris
  const [search, setSearch] = useState("Polestar Cars");
  // define state for requested page number
  const [reqPage, setReqPage] = useState(1);

  // our useFetch custom hook
  const { isLoading, error, data, pageRange } = useFetch(search, reqPage);

  // handeling search subbmition
  const searchHandler = (query: string) => {
    if (query !== search) {
      setReqPage(1);
    }
    setSearch(query);
  };
  // handeling next navigation
  const nextNavHandler = () => {
    setReqPage((page) => page + 1);
  };
  // handeling back navigation
  const backNavHandler = () => {
    setReqPage((page) => page - 1);
  };

  return (
    <div className={styles.container}>
      <SearchBox onSearch={searchHandler} />

      <h2>"{search}" Gallery</h2>

      {!isLoading && data && <Gallery photos={data} />}
      {!isLoading && !data && !error && <p>Found no photos!</p>}
      {!isLoading && error && <p>{error}</p>}
      {isLoading && <p>Loading...</p>}

      <Nav
        onNextClick={nextNavHandler}
        onBackClick={backNavHandler}
        page={reqPage}
        pageRange={pageRange}
      />
    </div>
  );
};

export default FlickrGallery;
