import { useState, useEffect, useCallback, useRef } from "react";
import Photo from "../models/photo";

// define Cache data type
type Cache = { [url: string]: Photo[] };

const useFetch = (query: string, reqPage: number) => {
  // define state for our data
  const [data, setData] = useState<Photo[]>([]);

  // define state for our Pages Range number
  const [pageRange, setPageRange] = useState(0);

  // define state for loading state
  const [isLoading, setIsLoading] = useState(false);

  // define state for error occued during the loading time
  const [error, setError] = useState(null);

  // use ref hook to cache our data ( in memory )
  const cache = useRef<Cache>({});

  // encode the url to be a vlid URI
  // configured the url to request data with 20 photos per page and json format
  // store API key in .env for best practing usage
  const url = window.encodeURI(
    `https://api.flickr.com/services/rest/?method=flickr.photos.search&api_key=${process.env.REACT_APP_API_KEY}&tags=${query}&per_page=20&page=${reqPage}&format=json&nojsoncallback=1`
  );

  // use Callback hook to prevent re-rendering when set some state in fetchPhoto function
  // and just re-call when "url" changed (actually our "query" or "reqPage" inputs)
  const fetchPhotos = useCallback(async () => {
    // starting the loading process
    setIsLoading(true);
    // reset the Error state
    setError(null);

    // if there is any cache data retrive it
    if (cache.current[url]) {
      setData(cache.current[url]);
    } else {
      // there was no cache and start a new request for newd ata
      try {
        const res = await fetch(url);
        // throw error if our reposne was not in 2XX range
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        const data = await res.json();

        // transforming the response data to array data as our desired
        const photosData = data.photos.photo.map((photoData: Photo) => {
          return {
            id: photoData.id,
            secret: photoData.secret,
            server: photoData.server,
            title: photoData.title,
          };
        });

        // save the data in ref cache according to its url
        cache.current[url] = photosData;

        // update our data States
        setData(photosData);
        setPageRange(data.photos.pages);
      } catch (err: any) {
        setError(err.message || "Something went wrong!");
      }
    }
    // ending the loading process - loaded
    setIsLoading(false);
  }, [url]);

  // use useEffect hook for our side effect Fetch API call inside the fetchPhotos function
  useEffect(() => {
    fetchPhotos();
  }, [fetchPhotos]);

  return {
    isLoading,
    error,
    data,
    pageRange,
  };
};

export default useFetch;
